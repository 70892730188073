import React, { Component } from 'react';
import { Layout, Card, Select, Button } from 'antd';
import { CustomerServiceTwoTone } from '@ant-design/icons';
import { wordsToLulu } from '../constants';
const { Content, Footer } = Layout;
const { Option } = Select;


export class App extends Component {
    constructor(props) {
        super(props);
        this.state = {
            isPlaying: true,
            source: './music/MySecret.mp3'
        };
    }
    render() {
        return (
            <Layout style={{minHeight:'100vh'}}>
                <Content>
                    <div style={{padding:'20px'}}>
                        {wordsToLulu}
                    </div>
                    <div style={{padding:'20px'}}>
                        <img src={'./lulumeng.jpg'} alt='lulu.jpg' width='300px' />
                    </div>
                </Content>
                <Footer>
                    <MusicComponent />
                </Footer>
            </Layout>
        );
    }
}

class MusicComponent extends Component {
    constructor(props) {
        super(props);
        this.state = {
            isPlaying: false,
            source: './music/MySecret.mp3'
        };
        this.handleMusicChange = this.handleMusicChange.bind(this);
        this.handlePauseResume = this.handlePauseResume.bind(this);
    }

    handleMusicChange(value) {
        document.getElementById('pauseResume').classList.add('anticon-spin');
        document.getElementById('audio').play();
        this.setState({
            isPlaying: true,
            source: `./music/${value}.mp3`
        });
    }

    handlePauseResume() {
        const { isPlaying } = this.state;
        if (isPlaying) {
            document.getElementById('pauseResume').classList.remove('anticon-spin');
            document.getElementById('audio').pause();
            this.setState({isPlaying: false});
        } else {
            document.getElementById('pauseResume').classList.add('anticon-spin');
            document.getElementById('audio').play();
            this.setState({isPlaying: true});
        }
    }

    componentDidMount() {
        document.getElementById('audio').volume = 0.04;
        // this.handlePauseResume();
    }

    render() {
        const {source} = this.state;
        return (
            <div>
                <Card title={'MusicBox'} style={{width:'300px',height:'114px'}}>
                    <Select showSearch style={{width:'200px'}} defaultValue={'MySecret'} onChange={this.handleMusicChange}>
                        <Option value='Drenched'>Drenched - Wanting Qu</Option>
                        <Option value='LoveCourage'>Love Courage - Wanting Qu</Option>
                        <Option value='MySecret'>My Secret - G.E.M</Option>
                        <Option value='SeeYou'>See You - G.E.M</Option>
                        <Option value='SummerWind'>Summer Wind - Lan Wen</Option>
                    </Select>
                    <Button
                        type='link'
                        icon={<CustomerServiceTwoTone id='pauseResume' twoToneColor={'#4CBB17'} style={{fontSize:'28px'}} />}
                        style={{width:'60px',height:'auto'}}
                        onClick={this.handlePauseResume}
                    />
                    <audio id='audio' autoPlay={true} loop={true} src={source} />
                </Card>
            </div>
        );
    }
}