import React from 'react';
import { Typography } from 'antd';
import {
    HeartTwoTone
} from '@ant-design/icons';
const { Text, Paragraph } = Typography;

const Components = {
    Heart: <HeartTwoTone twoToneColor={'#EB2F96'} style={{fontSize:'20px'}} />
};
export const wordsToLulu = <Text>
    <Paragraph>Lulu你好{Components.Heart}</Paragraph>
    <Paragraph>1946年2月14日，世界上第一台通用计算机 ENIAC 在美国宾夕法尼亚大学诞生。1985年1月1日，人类历史上首个域名 nordu.net 被注册。30多年后的今天，随着抖音等App的兴起，人们越来越少地使用域名。再过50年，人们或许已经不知道域名为何物。</Paragraph>
    <Paragraph>可是亲爱的Lulu，我还是想送给你一个域名。我在AWS上购买了 lulumarryme.com 10年。10年以后，AWS会自动续租这个域名10年，10年后再10年，然后再10年... 直到域名成为计算机历史书上的一个抽象的概念。那时候，我们孩子的孩子会笨拙地找到一个可以输入域名的软件，然后打开这个网页。</Paragraph>
    <Paragraph>我使用S3 serve了这个静态网页。假设AWS没有倒闭，那么，在我们的有生之年，在世界上的任何一个角落，你都可以打开这个网页，倾听「我的秘密」，回忆起这一天的甜蜜。我们的亲朋好友们，也都可以在网易云音乐会员到期的时候，来这里听盗版歌曲。</Paragraph>
    <Paragraph>一位前辈曾经告诉过我，找女朋友的两条黄金准则：一是她能让你变得更好，二是她能容忍你并且为你改变。Lulu，当我认识你的时候，我知道你完全符合这两条准则。可这都是对对方的要求，今天，我想再加一条步入婚姻的准则：Lulu，我是如此地爱你，我愿意为你付出我的全部。</Paragraph>
    <Paragraph>互联网会见证这一切。未来的孩子们，当你们看到这个网页的时候，希望你们还记得我们。亲爱的Lulu，我心里的秘密，是我会一直深爱着你。{Components.Heart}</Paragraph>
</Text>;